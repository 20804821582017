import React from 'react'
import Col from 'reactstrap/lib/Col'
import Container from 'reactstrap/lib/Container'
import Row from 'reactstrap/lib/Row'
import { bulkRedoRequest, bulkResultsReset } from '../../actions/bulkOperationsActions'
import { BulkRedoBase } from '../../api'
import { CommonProps } from '../../models'
import { BulkOperationsViewStore } from '../../reducers/states'
import { isNullOrWhitespace } from '../../utils'
import BulkRedoForm from '../forms/BulkRedoForm'
import { ResetReason } from '../../api'
import BulkProgress from './BulkProgress'

interface Props extends CommonProps, BulkOperationsViewStore {
  message: string
  productCodes: string
  resetReasons: ResetReason[]
}

class BulkRedoPanel extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
    this.sendBulkRedo = this.sendBulkRedo.bind(this)
  }

  sendBulkRedo(x: BulkRedoBase) {
    const productionOrderIdList = x.productionOrderIds
      .replaceAll('\r\n', ',')
      .replaceAll('\n', ',')
      .replaceAll('\r', ',')
      .replace(/\s/g, '')
      .split(',')
      .filter((x) => !isNullOrWhitespace(x)) //remove blank spaces and generate array

    this.props.dispatch(
      bulkRedoRequest({
        productionOrderIds: productionOrderIdList,
        recalculateCustomerPromiseDate: x.recalculateCustomerPromiseDate,
        reasonPhrase: x.reasonCode,
        reasonCode: x.reasonCode,
        comment: x.message
      })
    )
  }

  renderForm(resetReasons: ResetReason[]) {
    return <BulkRedoForm input=
      {{
        productionOrderIds: '',
        recalculateCustomerPromiseDate: true,
        message: '',
        resetReasons: resetReasons,
        reasonCode: '',
        showCpdConfirmation: false
      }}
      onSubmit={this.sendBulkRedo}></BulkRedoForm>
  }
  renderResponse() {
    return (
      <Container fluid className="p-0">
        <Row>
          <Col md="6">
            <h1>Bulk Redo Result</h1>
          </Col>
          <Col md="6" className="back-button">
            <button onClick={() => this.props.dispatch(bulkResultsReset({}))}> Back </button>
          </Col>
        </Row>
        <div className="response-container">
          {this.props.bulkRedoStatusResponse.result?.productionOrdersRedoSuccess && this.props.bulkRedoStatusResponse.result?.productionOrdersRedoSuccess.length > 0 && (
            <>
              <Row>
                <Col md="12" className='status-success status-heading'>
                  Redone Successfully
                </Col>
              </Row>
              <Row>
                <Col md="2" className="column-highlight">
                  Orders
                </Col>
                <Col md="10" className="column-highlight">
                  Production Order Ids
                </Col>
              </Row>
              {this.props.bulkRedoStatusResponse.result?.productionOrdersRedoSuccess.map((x, index) => (
                <Row key={index}>
                  <Col md="2" className="success-column">
                    {x.orderCode}
                  </Col>
                  <Col md="10" className="success-column">
                    {x.productionOrderIds?.join(', ')}
                  </Col>
                </Row>
              ))}
            </>
          )}
          {this.props.bulkRedoStatusResponse.result?.productionOrdersRedoFailed && this.props.bulkRedoStatusResponse.result?.productionOrdersRedoFailed.length > 0 && (
            <>
              <Row>
                <Col md="12" className='status-error status-heading'>
                  Redo Failed
                </Col>
              </Row>
              <Row>
                <Col md="2" className="column-highlight">
                  Orders
                </Col>
                <Col md="5" className="column-highlight">
                  Production Order Ids
                </Col>
                <Col md="5" className="column-highlight">
                  Error Message
                </Col>
              </Row>
              {this.props.bulkRedoStatusResponse.result?.productionOrdersRedoFailed.map((x, index) => (
                <Row key={index}>
                  <Col md="2" className="failed-column">
                    {x.orderCode}
                  </Col>
                  <Col md="5" className="failed-column">
                    {x.productionOrderIds?.join(', ')}
                  </Col>
                  <Col md="5" className="failed-column">
                    {x.errorMessage}
                  </Col>
                </Row>
              ))}
            </>
          )}
          {this.props.bulkRedoStatusResponse.result?.productionOrdersNotFound && this.props.bulkRedoStatusResponse.result?.productionOrdersNotFound.length > 0 && (
            <>
              <Row>
                <Col md="12" className='status-error status-heading'>
                  Production Orders Not Found
                </Col>
              </Row>
              <Row>
                <Col md="12" className="column-highlight">
                  Production Orders
                </Col>
              </Row>
              {this.props.bulkRedoStatusResponse.result?.productionOrdersNotFound.map((x, index) => (
                <Row key={index}>
                  <Col md="12" className="notfound-column">
                    {x}
                  </Col>
                </Row>
              ))}
            </>
          )}
        </div>
      </Container>
    )
  }
  renderProgress() {
    return (
      <BulkProgress itemsProcessed={this.props.bulkRedoStatusResponse.itemsProcessed} totalItems={this.props.bulkRedoStatusResponse.totalItems} remaining={this.props.bulkRedoStatusResponse.remaining} />
    )
  }

  render() {
    if (this.props.bulkRedoStatusResponse.inprogress) {
      return this.renderProgress();
    }
    return this.props.bulkRedoStatusResponse.valid ? this.renderResponse() : this.renderForm(this.props.resetReasons)
  }
}

export default BulkRedoPanel
