import { PayloadAction } from '@reduxjs/toolkit'
import { call, put, takeLatest } from 'redux-saga/effects'
import * as bulkOperationsActions from '../actions/bulkOperationsActions'
import * as global from '../actions/globalActions'
import { BulkRedoRequest } from '../api'
import { ApiServiceInstance } from '../services/ApiService'
import { Errors } from '../services/ErrorMessageService'
import { AxiosError } from 'axios'

function wait(ms: number) {
  var start = new Date().getTime();
  var end = start;
  while (end < start + ms) {
    end = new Date().getTime();
  }
}

function* bulkRedo(action: PayloadAction<BulkRedoRequest>) {
  try {
    const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

    yield put(global.showSpinner())
    let response = yield call(ApiServiceInstance.post, 'productionOrder/bulkRedo/start', action.payload)

    var requestId = response.data.id;
    while (response.result == null) {

      response = yield call(ApiServiceInstance.get, 'productionOrder/bulkRedo/status/' + requestId);
      yield put(global.hideSpinner())
      yield put(
        bulkOperationsActions.bulkRedoStatusResponse({
          ...response,
          inprogress: true,
        }));
      if (response.result == null) {
        wait(1000);
      }
    }
    yield put(
      bulkOperationsActions.bulkRedoStatusResponse({
        ...response,
        valid: true,
        inprogress: false,
      }));
  } catch (e) {
    console.error(e);
    let errorMessage = ''
    if (e.response?.data?.exception) {
      errorMessage = e.response.data.message + ": " + e.response.data.exception
    }
    else if (e.response?.data) {
      errorMessage = e.response.data;
    }
    else if (e.response?.exception) {
      errorMessage = e.response.message + ": " + e.response.exception
    }
    else {
      errorMessage = (e as AxiosError).message;
    }
    yield put(Errors.toErrorAction(e, errorMessage))
  } finally {
    yield put(global.hideSpinner())
  }
}

export default function* root() {
  yield takeLatest(bulkOperationsActions.bulkRedoRequest, bulkRedo)
}
