import { createAction } from '@reduxjs/toolkit'
import { NoteBulkRequest, NoteBulkStatusResponse, BulkReprocessRequest, BulkReprocessResponse, BulkRedoRequest, BulkRedoStatusResponse } from '../api'
import { BulkPromiseBrokenRequest, BulkPromiseBrokenResponse } from '../api/promiseBroken'
import { BulkRefinalizeRequest, BulkRefinalizeResponse } from '../api/refinalize'
import { BulkGetStatusRequest, BulkGetStatusResponse } from '../api/status'
import { BulkCancelRequest, BulkCancelResponse } from '../api/cancel'

export const bulkCommentRequest = createAction<NoteBulkRequest>('BULK_COMMENT_REQUEST')
export const bulkCommentStatusReceived = createAction<NoteBulkStatusResponse>('BULK_COMMENT_RECEIVED')
export const bulkRefinalizeRequest = createAction<BulkRefinalizeRequest>('BULK_REFINALIZE_REQUEST')
export const bulkRefinalizeReceived = createAction<BulkRefinalizeResponse>('BULK_REFINALIZE_RECEIVED')
export const bulkPromiseBrokenRequest = createAction<BulkPromiseBrokenRequest>('BULK_PROMISE_BROKEN_REQUEST')
export const bulkPromiseBrokenReceived = createAction<BulkPromiseBrokenResponse>('BULK_PROMISE_BROKEN_RECEIVED')
export const bulkReprocessRequest = createAction<BulkReprocessRequest>('BULK_REPROCESS_REQUEST')
export const bulkReprocessResponse = createAction<BulkReprocessResponse>('BULK_REPROCESS_RESPONSE')
export const bulkRedoRequest = createAction<BulkRedoRequest>('BULK_REDO_REQUEST')
export const bulkRedoStatusResponse = createAction<BulkRedoStatusResponse>('BULK_REDO_RESPONSE')
export const bulkResultsReset = createAction<any>('BULK_RESULTS_RESET')
export const bulkGetStatusRequest = createAction<BulkGetStatusRequest>('BULK_GET_STATUS_REQUEST')
export const bulkGetStatusResponse = createAction<BulkGetStatusResponse>('BULK_GET_STATUS_RESPONSE')
export const bulkCancelRequest = createAction<BulkCancelRequest>('BULK_CANCEL_REQUEST')
export const bulkCancelResponse = createAction<BulkCancelResponse>('BULK_CANCEL_RESPONSE')
export const tabIndexChange = createAction<number>('TAB_INDEX_CHANGE')
