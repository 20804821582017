import React, { FormEvent } from 'react'
import { Form, FormGroup, Input, Label, FormFeedback } from 'reactstrap'
import { BulkRedoBase } from '../../api/redo'
import { validators } from '../../utils'
import Select from './Select'

import './BulkRedoForm.scss'
import Confirmation from './Confirmation'

interface State {
  data: BulkRedoBase
  disabled: boolean,
  hasProductionOrderIdsError: boolean,
  hasReasonCodeError: boolean;
  hasMessageError: boolean
}

interface BulkRedoFormProps {
  input: BulkRedoBase
  onSubmit(form: BulkRedoBase): void
}

export default class BulkRedoForm extends React.Component<BulkRedoFormProps, State> {
  constructor(props: BulkRedoFormProps) {
    super(props)

    this.state = {
      data: {
        productionOrderIds: props.input.productionOrderIds,
        recalculateCustomerPromiseDate: true,
        message: props.input.message,
        resetReasons: props.input.resetReasons,
        reasonCode: props.input.reasonCode,
        showCpdConfirmation: false
      },
      disabled: false,
      hasProductionOrderIdsError: false,
      hasReasonCodeError: false,
      hasMessageError: false
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleGenericChange = this.handleGenericChange.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleReasonChange = this.handleReasonChange.bind(this)
    this.handleRecalculateCustomerPromiseDateChange = this.handleRecalculateCustomerPromiseDateChange.bind(this)
    this.onConfirmNoCpd = this.onConfirmNoCpd.bind(this)
    this.toggleConfirmation = this.toggleConfirmation.bind(this)
  }

  handleRecalculateCustomerPromiseDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    if (!checked) {
      this.setState(prevState => ({
        data: {
          ...prevState.data,
          showCpdConfirmation: true
        }
      }));
    } else {
      this.setState(prevState => ({
        data: {
          ...prevState.data,
          recalculateCustomerPromiseDate: true
        }
      }));
    }
  }

  handleReasonChange(event: any) {
    const target = event.target
    const code = target.value
    const [match] = this.props.input.resetReasons.filter((r) => r.errorID === code)

    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        reasonCode: match.errorID
      },
      hasReasonCodeError: this.isEmpty(code)
    } as State)
  }

  handleSubmit(event: FormEvent) {
    const hasMessageErrors = this.isEmpty(this.state.data.message)
    const hasReasonCodeError = this.isEmpty(this.state.data.reasonCode)
    const hasIdentifierErrors = this.isEmpty(this.state.data.productionOrderIds)

    this.setState({
      ...this.state,
      hasProductionOrderIdsError: hasIdentifierErrors,
      hasReasonCodeError: hasReasonCodeError,
      hasMessageError: hasMessageErrors
    } as State)

    if (hasIdentifierErrors || hasReasonCodeError || hasMessageErrors) {
      event.preventDefault()
      return;
    }
    else {
      this.setState({ disabled: true })
      this.props.onSubmit(this.state.data)
      this.setState({ disabled: false })
    }
  }

  handleGenericChange(event: any) {
    const target = event.target
    const value = target.value
    const name = target.name

    const errorProperty = target.name === 'productionOrderIds' ? 'hasProductionOrderIdsError' : 'hasMessageError'

    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        [name]: value
      },
      [errorProperty]: this.isEmpty(value)
    } as State)
  }

  handleBlur(event: any) {
    const target = event.target
    const value = target.value

    const errorProperty = target.name === 'productionOrderIds' ? 'hasProductionOrderIdsError' : 'hasMessageError'

    this.setState({
      ...this.state,
      [errorProperty]: this.isEmpty(value)
    } as State)
  }


  isEmpty(data: any) {
    return (data === null || data === undefined || data === '')
  }

  private toggleConfirmation() {
    this.setState(prevState => ({
      data: {
        ...prevState.data,
        showCpdConfirmation: false
      }
    }));
  }

  private onConfirmNoCpd() {
    this.setState(prevState => ({
      data: {
        ...prevState.data,
        recalculateCustomerPromiseDate: false,
        showCpdConfirmation: false
      }
    }));
  }

  renderCpdConfirmation() {
    const { showCpdConfirmation } = this.state.data
    console.log("renderCpdConfirmation: " + showCpdConfirmation)
    return <Confirmation
      title='Keep the existing Customer Promise Date Confirmation'
      isOpen={showCpdConfirmation}
      toggle={this.toggleConfirmation}
      onSubmit={this.onConfirmNoCpd}
      submitText="Yes"
      cancelText="No">
      Are you sure that you want to keep the original Customer Promise Date?

      This might cause production orders with an <b>unrealistic Customer Promise Date</b> sent to productionsites.
    </Confirmation>
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <FormGroup>
          <label className="field-help-text">The Bulk Redo operation supports ProductionOrderIds. Production Orders that were successfully redone will be cancelled. Use the Bulk Status endpoint to retrieve the latest status on the redone orders.</label>
          <Input type="textarea" rows={5} name="productionOrderIds" id="bulkRedoProductionOrderIds" value={this.state.data.productionOrderIds} onChange={this.handleGenericChange} invalid={this.state.hasProductionOrderIdsError} onBlur={this.handleBlur} />
          {this.state.hasProductionOrderIdsError && (
            <FormFeedback>{validators.required(this.state.data.productionOrderIds)}</FormFeedback>
          )}
          <label className="field-help-text">Insert ProductionOrderIds in comma separated fashion, for instance: ID1, ID2, ID3. Separate by new line is also supported.</label>
        </FormGroup>
        <FormGroup check>
          <Input
            type="checkbox"
            name="recalculateCustomerPromiseDate"
            id="recalculateCustomerPromiseDateCheckbox" // Add an ID for association
            checked={this.state.data.recalculateCustomerPromiseDate}
            onChange={this.handleRecalculateCustomerPromiseDateChange}
          />
          <Label for="recalculateCustomerPromiseDateCheckbox">&nbsp;&nbsp; Recalculate Customer Promise Date</Label>
        </FormGroup>
        <FormGroup>
          <Label className="col-form-label">Reason code</Label>
          <Select
            emptyText="-- select a code --"
            onChange={this.handleReasonChange}
            value={this.state.data.reasonCode}
            options={this.props.input.resetReasons.map((x) => ({ value: x.errorID, text: x.description }))}
            invalid={this.state.hasReasonCodeError}
          />
          {this.state.hasReasonCodeError && (
            <FormFeedback>{validators.required(this.state.data.reasonCode)}</FormFeedback>
          )}
        </FormGroup>
        <FormGroup>
          <Label className="col-form-label">Comment</Label>
          <Input type="textarea" rows={5} name="message" id="bulkRedoMessage" value={this.state.data.message} onChange={this.handleGenericChange} invalid={this.state.hasMessageError} onBlur={this.handleBlur} />
          {this.state.hasMessageError && (
            <FormFeedback>{validators.required(this.state.data.message)}</FormFeedback>
          )}
        </FormGroup>
        <FormGroup>
          <button type="submit" id="bulkRedoSubmit" disabled={this.state.disabled}>Submit</button>
        </FormGroup>
        {this.renderCpdConfirmation()}
      </Form>
    )
  }
}
